import Vue from 'vue'
import { Toast, Dialog, Popup, Icon, Radio, RadioGroup, Checkbox, CheckboxGroup, Swipe, SwipeItem, Loading, Picker } from 'vant'

Vue.use(Toast)
Vue.use(Dialog)
Vue.use(Popup)
Vue.use(Icon)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Loading)
Vue.use(Picker);
