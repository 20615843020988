import Requester from './request.js'
import { serverUrl } from './../utils/index'

const href = '/api'

class Api {
  // 获取用户信息
  static getUniqueSn(data = {}) {
    return Requester.post(`${serverUrl + href}/noauth/User/getUniqueSn`, data)
  }

  // 获取banner或首页产品列表
  static getListInfo(data = {}) {
    return Requester.post(`${serverUrl + href}/noauth/HomeInfo/getList`, data)
  }

  // 获取产品系列分类列表
  static getListGoodsClass(data = {}) {
    return Requester.post(`${serverUrl + href}/noauth/GoodsClass/getList`, data)
  }

  // 获取产品列表
  static getListGoods(data = {}) {
    return Requester.post(`${serverUrl + href}/noauth/Goods/getList`, data)
  }

  // 获取产品信息
  static getInfoGoods(data = {}) {
    return Requester.post(`${serverUrl + href}/noauth/Goods/getInfo`, data)
  }

  // 获取CLUB列表
  static getListClub(data = {}) {
    return Requester.post(`${serverUrl + href}/noauth/Club/getList`, data)
  }

  // 获取CLUB信息
  static getInfoClub(data = {}) {
    return Requester.post(`${serverUrl + href}/noauth/Club/getInfo`, data)
  }


}

export default Api
