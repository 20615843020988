/**
 * Created by peter on 16/12/18.
 */
import wx from 'weixin-js-sdk'
import axios from 'axios'
import UAParser from 'ua-parser-js'
import project from './../project/index'
import { serverUrl, DEBUG_MODE } from './../utils/index'

const href = '/api'

const os = new UAParser().getOS()

let initialized = false

const wechatAuth = (apiList = []) => {
  return new Promise((resolve, reject) => {
    let url = ''
    if (os.name === 'iOS') {
      url = localStorage.getItem('iosSignatureUrl')
    } else {
      url = window.location.protocol + '//' + window.location.host + window.location.pathname + window.location.search
    }

    let us = navigator.userAgent.toLowerCase();
    if (!(us.match(/MicroMessenger/i) == "micromessenger")) return

    axios.post(`${serverUrl + href}/noauth/WeiXin/getConfig`, {
      alias: project.mid, url
    })
      .then((r) => {
        const res = r.data.res
        if (parseInt(r.data.code) === 1) {
          wx.config({
            debug: false,
            appId: res.app_id,
            timestamp: res.timestamp,
            nonceStr: res.noncestr,
            signature: res.signature,
            jsApiList: apiList
          })

          wx.ready(() => {
            resolve()
          })

          wx.error(e => {
            reject(e)
          })
        }
      })
      .catch(e => {
        console.error(e)
      })
  })
}

class Wechat {
  // 微信分享
  share(obj = {}, func = null) {
    if (initialized && os.name === 'iOS') {
      const shareObj = {
        title: obj.title || '',
        desc: obj.desc || '',
        link: obj.link || window.location.href,
        imgUrl: obj.imgUrl,
        success: () => {
          if (func) func()
        }
      }

      wx.onMenuShareTimeline(shareObj)
      wx.onMenuShareAppMessage(shareObj)
      wx.onMenuShareQQ(shareObj)
      wx.onMenuShareQZone(shareObj)
      return
    }

    initialized = true

    const apiList = [
      'updateAppMessageShareData', // 分享给朋友或者qq好友
      'updateTimelineShareData', // 分享到朋友圈及qq空间
      'onMenuShareTimeline',
      'onMenuShareAppMessage',
      'onMenuShareQQ',
      'onMenuShareQZone',
      'onMenuShareWeibo', // 分享到腾讯微博
      'startRecord', // 音频接口
      'stopRecord',
      'onVoiceRecordEnd',
      'playVoice',
      'pauseVoice',
      'stopVoice',
      'onVoicePlayEnd',
      'uploadVoice',
      'downloadVoice',
      'chooseImage', // 图像接口
      'previewImage',
      'uploadImage',
      'downloadImage',
      'translateVoice', // 智能接口（语音识别）
      'getNetworkType', // 设备接口（网络状态）
      'openLocation', // 地理位置-微信地图查看位置
      'getLocation', // 获取位置
      'hideMenuItems', // 界面操作
      'showMenuItems',
      'hideAllNonBaseMenuItem',
      'showAllNonBaseMenuItem',
      'closeWindow', // 关闭当前网页
      'scanQRCode', // 微信扫一扫
      'chooseWXPay' // 微信支付
    ]

    wechatAuth(apiList)
      .then(() => {
        const shareObj = {
          title: obj.title || '',
          desc: obj.desc || '',
          link: obj.link || window.location.href,
          imgUrl: obj.imgUrl,
          success: () => {
            if (func) func()
          }
        }

        wx.onMenuShareTimeline(shareObj)
        wx.onMenuShareAppMessage(shareObj)
        wx.onMenuShareQQ(shareObj)
        wx.onMenuShareQZone(shareObj)
      })
      .catch(e => {
        console.error(e)
      })
  }

  // 禁止微信分享及屏蔽常用菜单功能
  prohibit() {
    if (initialized && os.name === 'iOS') {
      wx.hideAllNonBaseMenuItem()
      return
    }

    initialized = true

    const apiList = [
      'hideAllNonBaseMenuItem',
      'showAllNonBaseMenuItem',
      'closeWindow', // 关闭当前网页
    ]
    wechatAuth(apiList)
      .then(() => {
        wx.hideAllNonBaseMenuItem()
      })
      .catch(e => {
        console.error(e)
      })
  }
}

export { wx }
export default Wechat
