import Vue from 'vue'
import Router from 'vue-router'
import project from './../project/index'

const Home = () => import('./../views/home.vue')
const HomeEng = () => import('./../views/home_eng.vue')
const Login = () => import('./../views/login.vue')
const Index = () => import('./../views/index.vue')
const Product = () => import('./../views/product/index')
const ProductEng = () => import('./../views/product/index_eng.vue')
const ProductDetail = () => import('./../views/product/detail.vue')
const ProductDetailEng = () => import('./../views/product/detail_eng.vue')

const Story = () => import('../views/story.vue')
const StoryEng = () => import('../views/story_eng.vue')
const Club = () => import('../views/club/index.vue')
const ClubEng = () => import('../views/club/index_eng.vue')
const ClubDetail = () => import('../views/club/detail.vue')
const ClubDetailEng = () => import('../views/club/detail_eng.vue')
const Contactus = () => import('./../views/contactus.vue')
const ContactusEng = () => import('./../views/contactus_eng.vue')

const Brand = () => import('../views/brand.vue')
const BrandEng = () => import('../views/brand_eng.vue')

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: `/${project.mid}/`,
  routes: [
    {
      path: '/home',
      name: 'home',
      component: Home,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/home_eng',
      name: 'home_eng',
      component: HomeEng,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/index',
      name: 'index',
      component: Index,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/product',
      name: 'product',
      component: Product,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/product_eng',
      name: 'product_eng',
      component: ProductEng,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/product_detail',
      name: 'product_detail',
      component: ProductDetail,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/product_detail_eng',
      name: 'product_detail_eng',
      component: ProductDetailEng,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/story',
      name: 'story',
      component: Story,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/story_eng',
      name: 'story_eng',
      component: StoryEng,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/club',
      name: 'club',
      component: Club,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/club_detail',
      name: 'club_detail',
      component: ClubDetail,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/club_eng',
      name: 'club_eng',
      component: ClubEng,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/club_detail_eng',
      name: 'club_detail_eng',
      component: ClubDetailEng,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/contactus',
      name: 'contactus',
      component: Contactus,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/contactus_eng',
      name: 'contactus_eng',
      component: ContactusEng,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/brand',
      name: 'brand',
      component: Brand,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/brand_eng',
      name: 'brand_eng',
      component: BrandEng,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/',
      redirect: '/home_eng'
    },
    {
      path: '*',
      redirect: '/home_eng'
    },
  ],
  scrollBehavior(to, from, next) {
    return { left: 0, top: 0 }
  }
})
