<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  created() {
    const unique_sn = localStorage.getItem('unique_sn');
    if (!unique_sn) {
      this.$http.getUniqueSn()
        .then(r => {
          // console.log(r, '123')
          if (r && r.res) {
            localStorage.setItem('unique_sn', r.res.unique_sn)
          }
        })
    }
  }
}
</script>


<style>
#app {
  font-family: 'PingFang SC', 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
